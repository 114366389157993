export const environment = {
  production: true,
  APP_ID: 'sa.com.hessa.app',
  APP_NAME: 'Hessa',
  SENTRY_DSN: 'https://027000eeed2ce0a7591157b524a44713@o4506251732647936.ingest.us.sentry.io/4507576962580480',
  COMET_APP_ID: '2614749a773e7151',
  COMET_AUTH_KEY: 'bc6c8c5ca4c94bee5be95739aa8a59723b8544ee',
  MICROSOFT_APPLICATION_CLIENT_ID: 'c058ec19-25d2-463f-ac18-e5cb223a986e',
  MICROSOFT_AUTHORITY: 'https://login.microsoftonline.com/',
  MICROSOFT_REDIRECT_URI: '',
  COMET_REGION: 'in',
  FE_BASE_URL: 'https://hessa.com.sa',
  BE_API_BASE_URL: 'https://api.hessa.com.sa/api/v1',
  SOCKET_BASE_URL: 'https://ws.hessa.com.sa',
  AUTH_API_BASE_URL: 'https://auth.hessa.com.sa/api/v1',
  SMS_RECHARGE_URL: 'https://cloud.unifonic.com/payment/topup',
  ENVIRONMENT_NAME: 'production',
  MOCK_ENABLED: false,
};
